<template lang="pug">
</template>

<script>
import { onBeforeUnmount, onMounted } from 'vue';
import createREGL from 'regl';

import rectVert from '@/shaders/rect.vert';
import rectFrag from '@/shaders/rect.frag';

export default {
  name: 'CurvedPanel',
  setup() {
    const regl = createREGL();
    const draw = regl({
      vert: rectVert,
      frag: rectFrag,
      uniforms: {
        time: regl.context('time'),
      },
      attributes: {
        position: [
          [-1.0, 1.0],
          [-1.0, -1.0],
          [1.0, -1.0],
          [1.0, 1.0],
        ],
      },
      count: 4,
      primitive: 'triangle fan',
    });

    onMounted(() => {
      regl.frame(() => {
        draw();
      });
    });

    onBeforeUnmount(() => regl.destroy());
  },
};
</script>
